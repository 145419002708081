// ==============================
// Custom style
// 自定义样式
// ==============================
.single {
  .sponsor {
    padding-top: 0px;
    .sponsor-custom {
        margin-top: 0px;
        margin-bottom: 0px;
    }
  }
  .post-footer {
    margin-top: 0px;
  }
}
